import { materialsService } from '../../services/materials.service';
import * as ACTION from './types';

export function tableProps(page, sort, name) {
  return {
    type: ACTION.TABLE_PROPS,
    page,
    sort,
    name
  }
}

export function createMaterial(data) {
    return {
      types: [
        ACTION.LOADING_CREATE_MATERIAL,
        ACTION.RECEIVE_CREATE_MATERIAL,
        ACTION.ERROR_CREATE_MATERIAL,
      ],
      promise: materialsService.createMaterial(data)
    }
}

export function getActiveMaterials(cid, data, page, order, searchQuery) {
  return {
    types: [
      ACTION.LOADING_GET_ACTIVE_MATERIALS,
      ACTION.RECEIVE_GET_ACTIVE_MATERIALS,
      ACTION.ERROR_GET_ACTIVE_MATERIALS,
    ],
    promise: materialsService.getActiveMaterials(cid, data, page, order, searchQuery)
  }
}

export function getInactiveMaterials(cid, data, page, order, searchQuery) {
  return {
    types: [
      ACTION.LOADING_GET_INACTIVE_MATERIALS,
      ACTION.RECEIVE_GET_INACTIVE_MATERIALS,
      ACTION.ERROR_GET_INACTIVE_MATERIALS,
    ],
    promise: materialsService.getInactiveMaterials(cid, data, page, order, searchQuery)
  }
}

export function activateMaterials(id) {
  return {
    types: [
      ACTION.LOADING_ACTIVATE_MATERIALS,
      ACTION.RECEIVE_ACTIVATE_MATERIALS,
      ACTION.ERROR_ACTIVATE_MATERIALS,
    ],
    promise: materialsService.activateMaterials(id)
  }
}

export function inactivateMaterial(id) {
  return {
    types: [
      ACTION.LOADING_INACTIVATE_MATERIAL,
      ACTION.RECEIVE_INACTIVATE_MATERIAL,
      ACTION.ERROR_INACTIVATE_MATERIAL
    ],
    promise: materialsService.inactivateMaterial(id)
  }
}

export function getMaterialsByLevel(lid) {
  return {
    types: [
      ACTION.LOADING_GET_MATERIALS_BY_LEVEL,
      ACTION.RECEIVE_GET_MATERIALS_BY_LEVEL,
      ACTION.ERROR_GET_MATERIALS_BY_LEVEL,
    ],
    promise: materialsService.getMaterialsByLevel(lid)

  }
}

export function getGroupMaterials(gid) {
  return {
    types: [
      ACTION.LOADING_GET_GROUP_MATERIALS,
      ACTION.RECEIVE_GET_GROUP_MATERIALS,
      ACTION.ERROR_GET_GROUP_MATERIALS,
    ],
    promise: materialsService.getGroupMaterials(gid)

  }
}

export function getMaterialsRecord(range, filters, page, orderBy, groupId) {
  return {
    types: [
      ACTION.LOADING_GET_MATERIALS_RECORD,
      ACTION.RECEIVE_GET_MATERIALS_RECORD,
      ACTION.ERROR_GET_MATERIALS_RECORD,
    ], 
    promise: materialsService.getMaterialsRecord(groupId, range, filters, page, orderBy)
  }
}

export function ignoreMaterialConflicts() {
  return {
    type: ACTION.IGNORE_MATERIAL_CONFLICTS
  }
}

export function resetStateMaterialsRecord() {
  return { type: ACTION.RESET_STATE };
}
