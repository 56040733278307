export const TABLE_PROPS = 'MATERIALS::TABLE_PROPS';

export const LOADING_CREATE_MATERIAL = 'MATERIALS::LOADING_CREATE_MATERIAL';
export const RECEIVE_CREATE_MATERIAL = 'MATERIALS::RECEIVE_CREATE_MATERIAL';
export const ERROR_CREATE_MATERIAL = 'MATERIALS::ERROR_CREATE_MATERIAL';

export const LOADING_GET_ACTIVE_MATERIALS = 'MATERIALS::LOADING_GET_ACTIVE_MATERIALS';
export const RECEIVE_GET_ACTIVE_MATERIALS = 'MATERIALS::RECEIVE_GET_ACTIVE_MATERIALS';
export const ERROR_GET_ACTIVE_MATERIALS = 'MATERIALS::ERROR_GET_ACTIVE_MATERIALS';

export const LOADING_GET_INACTIVE_MATERIALS = 'MATERIALS::LOADING_GET_INACTIVE_MATERIALS';
export const RECEIVE_GET_INACTIVE_MATERIALS = 'MATERIALS::RECEIVE_GET_INACTIVE_MATERIALS';
export const ERROR_GET_INACTIVE_MATERIALS = 'MATERIALS::ERROR_GET_INACTIVE_MATERIALS';

export const LOADING_GET_MATERIALS_BY_LEVEL = 'MATERIALS::LOADING_GET_MATERIALS_BY_LEVEL';
export const RECEIVE_GET_MATERIALS_BY_LEVEL = 'MATERIALS::RECEIVE_GET_MATERIALS_BY_LEVEL';
export const ERROR_GET_MATERIALS_BY_LEVEL = 'MATERIALS::ERROR_GET_MATERIALS_BY_LEVEL';

export const LOADING_GET_GROUP_MATERIALS = 'MATERIALS::LOADING_GET_GROUP_MATERIALS';
export const RECEIVE_GET_GROUP_MATERIALS = 'MATERIALS::RECEIVE_GET_GROUP_MATERIALS';
export const ERROR_GET_GROUP_MATERIALS = 'MATERIALS::ERROR_GET_GROUP_MATERIALS';

export const LOADING_GET_MATERIALS_RECORD = 'MATERIALS::LOADING_GET_MATERIALS_RECORD';
export const RECEIVE_GET_MATERIALS_RECORD = 'MATERIALS::RECEIVE_GET_MATERIALS_RECORD';
export const ERROR_GET_MATERIALS_RECORD = 'MATERIALS::ERROR_GET_MATERIALS_RECORD';

export const LOADING_ACTIVATE_MATERIALS = 'MATERIALS::LOADING_ACTIVATE_MATERIALS';
export const RECEIVE_ACTIVATE_MATERIALS = 'MATERIALS::RECEIVE_ACTIVATE_MATERIALS';
export const ERROR_ACTIVATE_MATERIALS = 'MATERIALS::ERROR_ACTIVATE_MATERIALS';

export const LOADING_INACTIVATE_MATERIAL = 'MATERIALS::LOADING_INACTIVATE_MATERIAL';
export const RECEIVE_INACTIVATE_MATERIAL = 'MATERIALS::RECEIVE_INACTIVATE_MATERIAL';
export const ERROR_INACTIVATE_MATERIAL = 'MATERIALS::ERROR_INACTIVATE_MATERIAL';

export const IGNORE_MATERIAL_CONFLICTS = 'MATERIALS::IGNORE_MATERIAL_CONFLICTS';

export const RESET_STATE = 'MATERIALS::RESET_STATE';
