import React from 'react';
import Spinner from '../../simple/Spinner';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

import './Gmaps.scss';

const Map = withScriptjs(withGoogleMap(props => {

  const { markers } = props;

  return (
    <GoogleMap
      { ...props }
      defaultZoom={15}
      defaultCenter={{ lat: props.markers[0].latitude, lng: props.markers[0].longitude }}
    >
      {
        (markers || [ ]).map((marker, index) => <Marker key={index} position={{ lat: marker.latitude, lng: marker.longitude }} />)
      }
    </GoogleMap>
  )
}));

const Gmaps = props => {
  return (
    <div className='Gmaps'>
      <Map
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=places`}
        loadingElement={<Spinner />}
        containerElement={<div style={{ width: `100%`, height: `100%`, display: 'flex', justifyContent: 'center', alignItems: 'center' }} />}
        mapElement={<div style={{ width: `100%`, height: `100%` }} />}
        { ...props }
      />
    </div>
  )
};
  
export default Gmaps;