import { setSortDirection } from "../helpers/table";
import * as ACTION from "./types";

const dataState = {
    loading: {},
    errors: {},
    justifications: {
        result: [],
        catalogues: []
    },
    page: {
        justifications: 1
    },
    filters: {
    },
    orderBy: {
        justifications: {
            column: "date",
            order: "DESC"
        }
    },
};

const justifications = (state = dataState, action) => {

    switch (action.type) {
        case ACTION.JUSTIFICATIONS_LOADING:
            return {...state, loading: {...state.loading, JUSTIFICATIONS_LOADING: { state: true } } };
        case ACTION.JUSTIFICATIONS_ERROR:
            return {...state, loading: {...state.loading, JUSTIFICATIONS_LOADING: false }, errors: {...state.errors } };
        case ACTION.JUSTIFICATIONS_RECEIVE:
            return {...state, loading: {...state.loading, JUSTIFICATIONS_LOADING: undefined }, justifications: action.result.data.result };

        case ACTION.SAVE_FILTERS:
            return {...state, filters: action.filters };

        case ACTION.JUSTIFICATIONS_TABLEPROPS:
            state = setSortDirection(action, state);
            state.page[action.name] = action.page;
            return {...state };

        case ACTION.DELETE_EVIDENCE_LOADING:
            return {...state, loading: {...state.loading, DELETE_EVIDENCE_LOADING: { state: true } } };
        case ACTION.DELETE_EVIDENCE_ERROR:
            return {...state, loading: {...state.loading, DELETE_EVIDENCE_LOADING: { state: false, detail: action.error } } };
        case ACTION.DELETE_EVIDENCE_RECEIVE:
            return {...state, loading: {...state.loading, DELETE_EVIDENCE_LOADING: undefined }, deleteEvidence: action.result.data };
        
        case ACTION.REMOVE_EVIDENCE_STATE:
            let result = state.justifications.result.map(student => {
                if (student.id === action.payload.idStudent) {
                    let evidences = student?.evidences.filter(evidence => evidence.id !== action.payload.idEvidence);
                    return { ...student, evidences };
                } else {
                    return student;
                }
            });
            return { ...state, justifications: { ...state.justifications, result } };

        case ACTION.PUT_EVIDENCE_LOADING:
            return {...state, loading: {...state.loading, PUT_EVIDENCE_LOADING: { state: true } } };
        case ACTION.PUT_EVIDENCE_ERROR:
            return {...state, loading: {...state.loading, PUT_EVIDENCE_LOADING: { state: false, detail: action.error } } };
        case ACTION.PUT_EVIDENCE_RECEIVE:
            return {...state, loading: {...state.loading, PUT_EVIDENCE_LOADING: undefined }, putEvidence: action.result.data };
        
        case ACTION.RESET_STATE:
            return { ...dataState };
        
        default:
            return state;
    }
};

export default justifications;