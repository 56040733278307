import { setSortDirection } from "../helpers/table";
import * as ACTION from "./types";

const dataState = {
    loading: {},
    errors: {},
    events: {
        result: [],
        catalogues: []
    },
    page: {
        events: 1
    },
    filters: {
    },
    orderBy: {
        events: {
            column: "date",
            order: "DESC"
        }
    },
};

const groupEvent = (state = dataState, action) => {

    switch (action.type) {
        case ACTION.GROUP_EVENT_LOADING:
            return {...state, loading: {...state.loading, GROUP_EVENT_LOADING: { state: true } } };
        case ACTION.GROUP_EVENT_ERROR:
            return {...state, loading: {...state.loading, GROUP_EVENT_LOADING: false }, errors: {...state.errors } };
        case ACTION.GROUP_EVENT_RECEIVE:
            return {...state, loading: {...state.loading, GROUP_EVENT_LOADING: undefined }, events: action.result.data.result };

        case ACTION.SAVE_FILTERS:
            return {...state, filters: action.filters };

        case ACTION.GROUP_EVENT_TABLEPROPS:
            state = setSortDirection(action, state);
            state.page[action.name] = action.page;
            return {...state };

        case ACTION.RESET_STATE:
            return { ...dataState };
        
        default:
            return state;
    }
};

export default groupEvent;