import React from 'react'; 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getMaterialsRecord,
  tableProps,
  resetStateMaterialsRecord
} from '../../../reducers/materials/actions';
import columns from '../../TeacherDetail/table.settings'
import TableContainer from '../../../components/custom/TableContainer'

import './TableMaterials.scss';

function TableMaterials(props){
  let { match, state } = props;
  let {
    loading,
  } = state;
  return(
    <div className='TableMaterials'>
      <TableContainer downloadDisabled={ true } entity={ match.params } loading={ loading.MATERIALS_RECORD?.state } name='materialsRecord' columns={ columns } { ...props }/>
    </div>
  );
}

TableMaterials.propTypes = {}

const mapDispatchToProps = dispatch => bindActionCreators({
  getMaterialsRecord,
  tableProps,
  resetStateMaterialsRecord,
}, dispatch);
const mapStateToProps = state => ({
  state: state.get('materialsReducer'), 
});

export default connect(mapStateToProps, mapDispatchToProps)(TableMaterials);
