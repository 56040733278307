import { useEffect, useState } from "react";
import { Badge, Card, Col, DatePicker, Form, Radio, Row, Space, TimePicker, Typography } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';
import FormWrapper from "../FormWrapper";
import { getClasses } from "containers/Classes/actions";
import "./RepositionModal.scss";
import { createRepositions } from "reducers/group-cancellation/actions";

const databaseDateFormat = 'DD-MM-YYYY';
const databaseDateTimeFormat = `${databaseDateFormat} HH:mm`;
const fractionDuration = 30;
function RepositionModal(props) {
  const {
    classReducer,
    reposition,
    getClasses,
    onClose,
    groupCancellationReducer,
    createRepositions,
  } = props;

  const classStartTime = reposition.scheduleTime.split(' - ')[0];
  const classEndTime = reposition.scheduleTime.split(' - ')[1];
  const classStartDateTime = moment(`${reposition.scheduleDate} ${classStartTime}`, databaseDateTimeFormat);
  const classEndDateTime = moment(`${reposition.scheduleDate} ${classEndTime}`, databaseDateTimeFormat);
  
  const replacementTypes = [
    {
      label: "Completa",
      value: "complete",
    }, {
      label: "Por fracciones",
      value: "by-parts"
    }
  ];

  const replacementPositionOptions = [
    {
      label: "Agregar al inicio",
      value: "addBefore"
    }, {
      label: "Agregar al final",
      value: "addAfter"
    }    
  ];

  const [values, setValues] = useState({
    replacementType: replacementTypes[0].value,
  });

  const [form] = Form.useForm();

  const onChange = (formValue) => {
    setValues({
      ...values,
      ...formValue,
    });
  };

  const mapPayload = (formFieldsValue) => {
    let payload = {
      trackingID: reposition.trackingID,
      studentsForAgreement: [],
      type: formFieldsValue.replacementType,
      replacementDetail: [],
    };
    formFieldsValue.dates.map((date, repositionFormIndex) => {
      if (date) {
        const position = (formFieldsValue.replacementPosition || [])[repositionFormIndex];
        const repositionClassStartTime = formFieldsValue.replacementTime[repositionFormIndex].split(' - ')[0];
        const repositionClassEndTime = formFieldsValue.replacementTime[repositionFormIndex].split(' - ')[1];
        const cancelledClassDuration = moment.duration(classEndDateTime.diff(classStartDateTime)).asMinutes();
        const repositionDate = date.format(databaseDateFormat);
        const addBefore = position === 'addBefore';
        const addAfter = position === 'addAfter';
        const isCompleteReposition = formFieldsValue.replacementType === 'complete';
        let start = moment(`${repositionDate} ${repositionClassStartTime}`, databaseDateTimeFormat);
        let end = moment(`${repositionDate} ${repositionClassEndTime}`, databaseDateTimeFormat);
        if (addBefore) {
          start.subtract(fractionDuration, 'minutes');
        }
        if (addAfter) {
          end.add(fractionDuration, 'minutes');
        }
        if (isCompleteReposition) {
          start = moment(`${repositionDate} ${formFieldsValue.time.format('HH:mm')}`, databaseDateTimeFormat);
          end = moment(start).add(cancelledClassDuration, 'minutes');
        }
        payload.replacementDetail.push(
          {
            ...reposition,
            studentsForAgreement: true,
            start: start.format(databaseDateTimeFormat+':ss'),
            end: end.format(databaseDateTimeFormat+':ss'),
            endDatetime: end.valueOf(),
            startDatetime: start.valueOf(),
            addBefore,
            addAfter,
            isReplacement: true,
            isCancelled: false,
            isCurrentClass: false,
            isPartOfReplacement: !isCompleteReposition,
          }
        );
      }
      return date;
    });

    return payload;
  };
  const onFinish = () => {
    const payloadMapped = mapPayload(form.getFieldsValue());
    createRepositions(reposition.id, payloadMapped);
  };

  const getClassesOfTheDay = (date) => {
    return (classReducer.classesOrigin || []).find((day) => day.date === date)?.classes || [];
  };

  const dateRender = (current, today) => {
    const cellDate = current.format(databaseDateFormat);
    const classesOfTheDay = getClassesOfTheDay(cellDate);
    const isCancelledDate = cellDate === reposition.scheduleDate;
    const hasClasses = classesOfTheDay.length > 0;

    return (
      <div className={`ant-picker-cell-inner ${hasClasses && 'day-has-classes'} ${isCancelledDate && 'is-cancelled-date'}`}>
        {current.date()}
      </div>
    );
  };

  const panelRender = (panel) => {
    return (
      <Col>
        <Row>
          {panel}
        </Row>
        <Row>
          <Space direction="horizontal" size="small">
            <Badge color="#d9d9d9" text={'Clase cancelada'} />
            <Badge color="#809DB3" text={'Clase activa'} />
          </Space>
        </Row>
      </Col>
    );
  };

  const fetchClasses = (date) => {
    const payload = {
      startdate: date.startOf('month').format('YYYY-MM-DD'),
      enddate: date.endOf('month').format('YYYY-MM-DD'),
      clients: [],
      teachers: [],
      languages: [],
      groups: [reposition.groupId],
      levels: [],
    };
    getClasses(payload);
  }

  const handleOnDatePickerMonthChange = (date) => {
    fetchClasses(date);
  };

  useEffect(() => {
    if (groupCancellationReducer.loading.CREATE_REPOSITIONS?.state === 'success') {
      window.location.reload();
    }
  }, [groupCancellationReducer.loading.CREATE_REPOSITIONS]);
  
  useEffect(() => {
    fetchClasses(moment());
  }, []);
  
  const originalClassDate = moment(reposition.scheduleDate, databaseDateFormat).format('dddd DD [de] MMMM');

  const renderRepositionTimePicker = () => {
    const formItems = [];
    const maxItems = values.replacementType === 'complete' ? 1 : reposition.availableParts;
    const selectedDates = form.getFieldValue('dates');
    const eventsOfSelectedDate = (selectedDates || []).map(date => getClassesOfTheDay(date.format(databaseDateFormat)) || []);
    for (let i = 0; i < maxItems; i++) {
      formItems.push(
        <Form.Item
          label={`Fecha de reposición ${i + 1}`}
          name={['dates', i]}
        >
          <DatePicker panelRender={panelRender} popupClassName="reposition-datepicker" onPanelChange={handleOnDatePickerMonthChange} dateRender={dateRender} />
        </Form.Item>
      );
      if (eventsOfSelectedDate[i]?.length > 0) {
        formItems.push(
          <>
            <Typography className="ant-form-item-label">Clases programadas para el {selectedDates[i]?.format('dddd DD [de] MMMM')}:</Typography>
            {eventsOfSelectedDate[i].map((classItem) => (
              <Card type="inner">
                <Col>
                  <Row>
                    <Typography.Text><span>Horario: </span> {classItem.scheduleTime}</Typography.Text>

                    <Form.Item
                      label={`Reposición de ${fractionDuration} minutos`}
                      name={['replacementTime', i]}
                      initialValue={classItem.scheduleTime}
                      hidden
                    >
                      <input type="hidden" />
                    </Form.Item>
                  </Row>
                  {classItem.scheduleDate === reposition.scheduleDate && (
                    <Row>
                      <div className='cancelled status-chip'>Clase cancelada</div>
                    </Row>
                  )}
                  {values.replacementType === 'by-parts' && classItem.scheduleDate !== reposition.scheduleDate && (
                    <Row>
                      <Form.Item
                        label={`Reposición de ${fractionDuration} minutos`}
                        name={['replacementPosition', i]}
                      >
                        <Radio.Group options={replacementPositionOptions} />
                      </Form.Item>
                    </Row>
                  )}
                </Col>
              </Card>
            ))}
          </>
        );
      }
    }
    return formItems;
  };

  return (
    <div className="class-modal">
      <h1>¿Deseas reagendar esta clase?</h1>
      <Card title={reposition.clientName} type="inner">
        <Col>
          <Row>
            <Typography.Text><span>Grupo:</span> {reposition.classGroup}</Typography.Text>
          </Row>
          <Row>
            <Typography.Text><span>Horario anterior:</span> {originalClassDate} | {reposition.scheduleTime}</Typography.Text>
          </Row>
        </Col>
      </Card>
      <div>
        <FormWrapper
          name="class-reposition"
          form={form}
          initialValues={values}
          onValuesChange={onChange}
          onFinish={onFinish}
          loading={(classReducer.loading.GET_CLASSES?.state || groupCancellationReducer.loading.CREATE_REPOSITIONS?.state) === true}
          cancelButtonText="Regresar"
          saveButtonText="Continuar"
          onRedirectCancelButton={() => onClose()}
        >
          <div className="container-form">
            <Form.Item
              label="Tipo de reposición"
              name="replacementType"
              rules={[{ required: true }]}
            >
              <Radio.Group
                options={replacementTypes}
              />
            </Form.Item>
            {values.replacementType === 'by-parts' && (
              <Form.Item
                label="Por agendar:"
              >
                {reposition.availableParts} {reposition.availableParts > 1 ? 'fracciones' : 'fracción'} de 30 minutos
              </Form.Item>
            )}
            {renderRepositionTimePicker()}
            {values.replacementType === 'complete' && (
              <Form.Item
                label="Horario"
                name="time"
                rules={[
                  {
                    required: true,
                    message: 'Ingresa un rango de horas válido'
                  }
                ]}
              >
                <TimePicker 
                  popupClassName='group-schedule-timepicker' 
                  minuteStep={ 15 } 
                  use12Hours
                  format="h:mm a"
                />
              </Form.Item>
            )}
          </div>
        </FormWrapper>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getClasses, createRepositions }, dispatch);
const mapStateToProps = (state) => ({
  classReducer: state.get('classReducer'),
  groupCancellationReducer: state.get('groupCancellationReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(RepositionModal);
