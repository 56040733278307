import moment from 'moment';
import './ClasseEvent.scss';

function ClasseEvent(props) {
  let date = moment(props.scheduleDate, 'DD-MM-YYYY');
  const {isCancelled} = props;
  return (
    <div className='ClasseEvent'>
      <div className='ClasseEvent__date'>
        <div className='ClasseEvent__date__icon'>
          <i className="material-icons">event</i>
        </div>
        <div className='ClasseEvent__date__date'>
          <div>{ date._isValid ? date.format('DD [de] MMMM') : '' }</div>
          <div>{ date._isValid ? date.format('dddd') : '' }</div>
          {isCancelled && <div className='cancelled status-chip'>Clase cancelada</div>}
        </div>
      </div>
      {/* <div className='ClasseEvent__event'>
        <label>Sin eventos programados</label>
        <Button icon={ <PlusOutlined/> }>Agregar evento</Button>
      </div> */}
    </div>
  );
}


export default ClasseEvent;
