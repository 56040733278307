export const JUSTIFICATIONS_LOADING = 'JUSTIFICATIONS::JUSTIFICATIONS_LOADING';
export const JUSTIFICATIONS_RECEIVE = 'JUSTIFICATIONS::JUSTIFICATIONS_RECEIVE';
export const JUSTIFICATIONS_ERROR = 'JUSTIFICATIONS::JUSTIFICATIONS_ERROR';

export const JUSTIFICATIONS_TABLEPROPS = 'JUSTIFICATIONS::JUSTIFICATIONS_TABLEPROPS';
export const SAVE_FILTERS = 'JUSTIFICATIONS::SAVE_FILTERS';

export const DELETE_EVIDENCE_LOADING = 'JUSTIFICATIONS::DELETE_EVIDENCE_LOADING';
export const DELETE_EVIDENCE_RECEIVE = 'JUSTIFICATIONS::DELETE_EVIDENCE_RECEIVE';
export const DELETE_EVIDENCE_ERROR = 'JUSTIFICATIONS::DELETE_EVIDENCE_ERROR';

export const REMOVE_EVIDENCE_STATE = 'JUSTIFICATIONS::REMOVE_EVIDENCE_STATE';

export const PUT_EVIDENCE_LOADING = 'JUSTIFICATIONS::PUT_EVIDENCE_LOADING';
export const PUT_EVIDENCE_RECEIVE = 'JUSTIFICATIONS::PUT_EVIDENCE_RECEIVE';
export const PUT_EVIDENCE_ERROR = 'JUSTIFICATIONS::PUT_EVIDENCE_ERROR';

export const RESET_STATE = 'JUSTIFICATIONS::RESET_STATE';
