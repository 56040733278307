import { setSortDirection } from "../helpers/table";
import * as ACTION from "./types";

const dataState = {
    loading: {},
    errors: {},
    tests: {
        result: [],
        catalogues: []
    },
    page: {
        tests: 1
    },
    exam: {},
    filters: {
        startDate: null,
        student: null,
        type: undefined,
        result: undefined,
        status: undefined
    },
    orderBy: {
        tests: {
            column: "examDate",
            order: "DESC"
        }
    },
    form: {
        startTime: null,
        endTime: null,
        full_name: null,
        isApproved: null,
        status: null
    },
    types: [
        { value: 'Parcial', label: 'Parcial' },
        { value: 'Examen', label: 'Examen' },
        { value: 'Test de ubicación', label: 'Test de ubicación' },
    ],
    results: [
        { label: 'Sin realizar', value: 2 },
        { label: 'Aprobado', value: 1 },
        { label: 'No aprobado', value: 0 },
    ],
    status: [
        { label: 'Validado', value: 'Validado' },
        { label: 'Sin validar', value: 'Sin validar' },
    ],
    students: [],
};

const groupExams = (state = dataState, action) => {
    switch (action.type) {
        case ACTION.STUDENTS_LOADING:
            return {...state, loading: {...state.loading, STUDENTS_LOADING: true } };
        case ACTION.STUDENTS_ERROR:
            return {...state, loading: {...state.loading, STUDENTS_LOADING: false }, errors: {...state.errors, STUDENTS_LOADING: action.error } };
        case ACTION.STUDENTS_RECEIVE:
            return {...state, loading: {...state.loading, STUDENTS_LOADING: null }, students: action.result.data.result.map(item => ({...item, title: item.name })) };

        case ACTION.SAVE_FILTERS:
            return {...state, filters: action.filters, page: 1 };

        case ACTION.GROUP_EXAMS_LOADING:
            return {...state, loading: {...state.loading, TESTS: { state: true } } }
        case ACTION.GROUP_EXAMS_RECEIVE:
            return {...state, loading: {...state.loading, TESTS: undefined }, tests: action.result.data };
        case ACTION.GROUP_EXAMS_ERROR:
            return {...state, loading: {...state.loading, TESTS: { state: false, detail: action.error } } }

        case ACTION.GROUP_EXAMS_TABLEPROPS:
            state = setSortDirection(action, state);

            state.page[action.name] = action.page;
            return {...state };
        
        case ACTION.RESET_STATE:
            return { ...dataState };

        default:
            return state;
    }
};

export default groupExams;