// eslint-disable-next-line
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTypes } from "reducers/groups/actions";

const TypesController = ({
  getTypes,
  groupsReducer,
  children,
  cancellationType
}) => {
  const { types } = groupsReducer;

  useEffect(() => {
    getTypes();
  }, []);
  
  const mapReasonsForAbsence = () => {
    const cancellationFilters = {
      'S': ['A4', 'A5', 'A6', 'A7'],
      'M-': ['A2', 'A3', 'A8'],
      'M+': [],
      'C+': ['A', 'A1', 'A2', 'A3', 'A4'],
      'C-': ['A', 'A1', 'A2', 'A3', 'A4'],
    };
    return (types?.reasonsForAbsence || [])
      .filter((type) => cancellationFilters[cancellationType]?.find(assistanceTypeId => assistanceTypeId === type.id))
  }

  return (
    <>
      {children({...types, cancellationTypes: types?.cancellationTypes || [], reasonsForAbsence: mapReasonsForAbsence()})}
    </>
  );
};


const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getTypes }, dispatch);
const mapStateToProps = (state) => ({
  groupsReducer: state.get("groupsReducer"),
});

export default connect(mapStateToProps, mapDispatchToProps)(TypesController);
