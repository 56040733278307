import { useEffect, useState } from "react";
import { Form, Radio } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTypes, setCancellation } from "../../../reducers/groups/actions";
import moment from 'moment';
import FormWrapper from "../FormWrapper";
import TypesController from "controllers/TypesController";
import "./CancellationClassModal.scss";

function CancellationClassModal(props) {
  const {
    groupsReducer,
    startDate,
    setCancellation,
    match: { params },
    onClose,
    students
  } = props;

  const { types } = groupsReducer;

  const { classId } = params;

  const [values, setValues] = useState({
    cancellationType: null,
    reasonsForAbsence: null,
    showReasons: false,
  });

  const [form] = Form.useForm();


  const onChange = (formValue) => {
    const showReasons = types?.cancellationTypes.find((type) => type.id === (formValue.cancellationType || values.cancellationType))?.showReasons;
    setValues({
      ...values,
      ...formValue,
      showReasons,
      reasonsForAbsence: showReasons ? formValue.reasonsForAbsence : undefined,
    });
  };

  const onFinish = () => {
    setCancellation(classId, values.cancellationType, values.reasonsForAbsence, students);
  };

  const mapTypes = (types) => {
    return types.map((item) => ({
      value: item.id,
      label: item.description
    }));
  }

  useEffect(() => {
    if (groupsReducer.loading.SET_CANCELLATION?.state === 'success') {
      window.location.reload();
    }
  }, [groupsReducer.loading.SET_CANCELLATION]);

  return (
    <div className="edit-teacher-modal">
      <h1>Cancelar clase del {moment(startDate, 'DD-MM-YYYY').format('DD [de] MMMM')}</h1>
      <div>
        <TypesController cancellationType={values.cancellationType}>
          {({ reasonsForAbsence, cancellationTypes }) => (
            <FormWrapper
              name="teacher-assignation"
              form={form}
              initialValues={values}
              onValuesChange={onChange}
              onFinish={onFinish}
              loading={groupsReducer.loading.SET_CANCELLATION?.state}
              cancelButtonText="Regresar"
              saveButtonText="Continuar"
              onRedirectCancelButton={() => onClose()}
            >
              <div className="container-form">
                <Form.Item
                  label="Tipo de cancelación"
                  name="cancellationType"
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={mapTypes(cancellationTypes)} />
                </Form.Item>
                {values.showReasons && (
                  <Form.Item
                    label="Elige el motivo de la cancelación"
                    name="reasonsForAbsence"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group options={mapTypes(reasonsForAbsence)} />
                  </Form.Item>
                )}
              </div>
            </FormWrapper>
          )}
        </TypesController>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getTypes, setCancellation }, dispatch);
const mapStateToProps = (state) => ({
  groupsReducer: state.get("groupsReducer"),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancellationClassModal);
