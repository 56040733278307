import React, { useState, useEffect } from 'react';
import CircleGraph from '../../simple/CircleGraph';
import SpinnerArea from '../../custom/SpinnerArea';
import Modal from '../../simple/Modal';
import Flags from '../../simple/Flags';
import ReportModal from '../ReportModal';
import { ArrowDownOutlined, CalendarOutlined } from '@ant-design/icons';
import Gmaps from '../Gmaps';
import { Radio, Button, Dropdown, Divider } from 'antd';
import EditScheduleModal from '../EditScheduleModal';
import ModalClosable from '../ModalClosable';
import ClassSuspensionModal from '../ClassSuspensionModal';
import moment from 'moment';
import EditTeacherAsignationModal from '../EditTeacherAsignationModal/EditTeacherAsignationModal';
import ModalController from 'controllers/ModalController';
import CancellationClassModal from '../CancellationClassModal';
import './ClassDetailCard.scss';

function ClassDetailCard(props) {
  const {
    getStudyPlan,
    gettingClass,
    scheduleTime,
    teacher,
    client,
    group,
    replacementPolicies,
    updateReplacementPolicy,
    refresh,
    isVirtualClass,
    classReducer,
    scheduleDate,
    isCancelled,
  } = props;

  const {
    groupStudyPlan
  } = classReducer;
  const [ replacementPolicy, setReplacementPolicy ] = useState(1);

  useEffect(() => {
    setReplacementPolicy(group.replacementPolicy.id);
    if (group.id && client.id) {
      getStudyPlan(client.id, group.id);
    }
  }, [ group, client, getStudyPlan ]);

  const hasValidSchedule = moment(groupStudyPlan?.initial_date, 'YYYY-MM-DD').year() > 2022;

  const scheduleMenu = (
    <div className="dropdown-menu">
      <ul>
        {hasValidSchedule && (
          <ModalController
            trigger={({onToggleModal}) => (
              <li onClick={onToggleModal} >Editar agenda</li>
            )}
            modal={({isModalOpen, onToggleModal}) => (
              <ModalClosable
                visible={isModalOpen} 
                placement="left"
                children={ 
                  <EditScheduleModal 
                    { ...props }
                    onClose={onToggleModal} 
                  /> 
                }
              />
            )}
          />
        )}
        <Divider className="spacing" />
        <ModalController
          trigger={({onToggleModal}) => (
            <li onClick={onToggleModal}>Suspensión de clases</li>
          )}
          modal={({isModalOpen, onToggleModal}) => (
            <ModalClosable
              visible={ isModalOpen } 
              placement="left"
              children={ 
                <ClassSuspensionModal 
                  { ...props } 
                  onClose={onToggleModal}
                /> 
              }
            />
          )}
        />
      </ul>
    </div>
  );

  const editMenu = (
    <div className="dropdown-menu">
      <ul>
        <ModalController
          trigger={({onToggleModal}) => (
            !isCancelled && (
              <li onClick={onToggleModal} >Editar profesor</li>
            )
          )}
          modal={({isModalOpen, onToggleModal}) => (
            <ModalClosable
              visible={ isModalOpen } 
              placement="left"
              children={ 
                <EditTeacherAsignationModal 
                  { ...props }
                  startDate={scheduleDate}
                  onClose={onToggleModal} 
                /> 
              }
            />
          )}
        />
        <Divider className="spacing" />
        {hasValidSchedule && <li onClick={() => props.history.push({pathname: `/clients/${ client?.id }/group/${ group?.id }`, state: { statusGroup: 'completed'}})} >Editar grupo</li>}
        <ModalController
          trigger={({onToggleModal}) => (
            !isCancelled && (
              <>
                <Divider className="spacing" />
                <li onClick={onToggleModal} >Cancelar clase</li>
              </>
            )
          )}
          modal={({isModalOpen, onToggleModal}) => (
            <ModalClosable
              visible={ isModalOpen } 
              placement="left"
              children={
                <div>
                  <CancellationClassModal
                    { ...props }
                    startDate={scheduleDate}
                    onClose={onToggleModal} 
                  />
                </div>
              }
            />
          )}
        />
      </ul>
    </div>
  );

  if (gettingClass || !client.name) {
    return (
      <div className='ClassDetailCard'>
        <SpinnerArea size='large'/>
      </div>
    );
  }
  return(
    <div className={`ClassDetailCard ${isVirtualClass && 'is_virtual_class'}`}>
      <div className='ClassDetailCard__data'>
        <div className='ClassDetailCard__data__level'>
          <CircleGraph level={group.level} porcentage={(group.currentClass * 100) / group.totalClassesOfLevel} />
        </div>
        <div className='ClassDetailCard__data__data'>
          <div className='ClassDetailCard__data__data__title'>
            <div className='ClassDetailCard__data__data__title__name'>
              <h1>{ client.name }</h1>
              <h2>{ group.name }</h2>
              {!hasValidSchedule && <label className="invalid-schedule-label">Gestionado por My-Speak</label>}
            </div>
            <ModalController
              trigger={({onToggleModal}) => (
                <Button onClick={onToggleModal} type="primary" shape="circle" icon={ <ArrowDownOutlined /> } />
              )}
              modal={({isModalOpen, onToggleModal}) => (
                <ReportModal { ...props } visible={isModalOpen} onClose={onToggleModal}/>
              )}
            />
            <Dropdown overlay={editMenu}>
              <Button
                type="primary" 
                shape="circle"
                style={{backgroundColor: '#ebaa4d'}} 
                icon={ <i className="material-icons button icon-button-custon">edit</i> } 
              />
            </Dropdown>
            <Dropdown overlay={scheduleMenu}>
              <Button
                type="primary" 
                shape="circle" 
                style={{backgroundColor: '#335267'}} 
                icon={ <CalendarOutlined /> } 
              />
            </Dropdown>
          </div>
          <div className='ClassDetailCard__data__data__more'>
            <div className='ClassDetailCard__data__data__more__section'>
              <div>
                <i className="material-icons">schedule</i>
                { scheduleTime || 'No especificado' }
              </div>
              <div>
                <i className="material-icons">card_travel</i>
                { group.scheduleDays }
              </div>
              <div>
                <a target="blank" href={`https://www.google.com/maps?q=${group.latitude},${group.longitude}`}>
                  <i className="material-icons">location_on</i>
                  { group.address || 'No especificado' }
                </a>
              </div>
              <ModalController
                trigger={({onToggleModal}) => (
                  <div className="edit" onClick={onToggleModal}>
                    <i className="material-icons">sms_failed</i>
                    <span>Política de cancelación:<br/>{ group.replacementPolicy.name || 'No especificado' }</span>
                    <i className="material-icons button">edit</i>
                  </div>
                )}
                modal={({isModalOpen, onToggleModal}) => (
                  <Modal modalProps={{
                    placement: "left",
                    closable: false,
                    onOk: () => {
                      updateReplacementPolicy(group.id, replacementPolicy).then(() => {
                        onToggleModal();
                        refresh();
                      });
                    },
                    onCancel: onToggleModal,
                    cancelText: 'Regresar',
                    okText: 'Guardar cambio',
                    title: 'Editar política de cancelación',
                  }} visible={ isModalOpen } content={
                    <Radio.Group className='replacementPolicy' style={{
                      margin: 'auto',
                      display: 'table'
                    }} name='replacementPolicy' value={replacementPolicy} onChange={input => {
                        setReplacementPolicy(input.target.value);
                    }} options={replacementPolicies.map(item => ({
                      value: item.id,
                      label: item.name
                    }))}/>
                  }/>
                )}
              />
            </div>
            <div className='ClassDetailCard__data__data__more__section'>
              {hasValidSchedule && (
                <div>
                  <i className="material-icons">local_atm</i>
                  Plan { `${groupStudyPlan?.plan_description}` || 'no especificado' } {' '}
                  - Tarifa $ { groupStudyPlan?.tariff_client || 'no especificada' }
                </div>
              )}
              {isVirtualClass && (
                <div>
                  <i className="material-icons">videocam</i>
                  Videoconferencia
                </div>
              )}
              <div>
                <i className="material-icons">person_outline</i>
                { teacher.name || 'No especificado' }
              </div>
              <div>
                <i className="material-icons">chat_bubble_outline</i>
                { groupStudyPlan?.description || 'No especificado'}
                <Flags value={ groupStudyPlan?.study_plan_id }/>
              </div>
              {hasValidSchedule && (
                <div>
                  <i className="material-icons">date_range</i>
                  Fecha de inicio: { moment(groupStudyPlan?.initial_date).format('DD/MM/YYYY') || 'No especificado' }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!isVirtualClass && (
        <div className='ClassDetailCard__map'>
          { group.latitude && group.longitude ? (
              <Gmaps
                markers={[
                  { latitude: parseFloat(group.latitude), longitude: parseFloat(group.longitude) },
                ]}
              />
            ) : <img src={require(`../../../assets/img/sin-direccion.jpg`)} alt={`${ group.language }`} />
          }
        </div>
      )}
    </div>
  );
}


ClassDetailCard.propTypes = {}

export default ClassDetailCard;