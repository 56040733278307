export const ADD_DATA = 'CLASSES::ADD_DATA';

export const LOADING_GET_CLASSES = 'CLASSES::LOADING_GET_CLASSES';
export const RECEIVE_GET_CLASSES = 'CLASSES::RECEIVE_GET_CLASSES';
export const ERROR_GET_CLASSES = 'CLASSES::ERROR_GET_CLASSES';

export const LOADING_GET_CLIENT_GROUPS = 'CLASSES::LOADING_GET_CLIENT_GROUPS';
export const RECEIVE_GET_CLIENT_GROUPS = 'CLASSES::RECEIVE_GET_CLIENT_GROUPS';
export const ERROR_GET_CLIENT_GROUPS = 'CLASSES::ERROR_GET_CLIENT_GROUPS';

export const CLEAR_CLIENT_GROUPS = 'CLASSES::CLEAR_CLIENT_GROUPS';

export const LOADING_GET_CATALOGS = 'CLASSES::LOADING_GET_CATALOGS';
export const RECEIVE_GET_CATALOGS = 'CLASSES::RECEIVE_GET_CATALOGS';
export const ERROR_GET_CATALOGS = 'CLASSES::ERROR_GET_CATALOGS';

export const LOADING_GET_CLASS = 'CLASSES::LOADING_GET_CLASS';
export const RECEIVE_GET_CLASS = 'CLASSES::RECEIVE_GET_CLASS';
export const ERROR_GET_CLASS = 'CLASSES::ERROR_GET_CLASS';

export const LOADING_GET_STUDENTS = 'CLASSES::LOADING_GET_STUDENTS';
export const RECEIVE_GET_STUDENTS = 'CLASSES::RECEIVE_GET_STUDENTS';
export const ERROR_GET_STUDENTS = 'CLASSES::ERROR_GET_STUDENTS';

export const LOADING_SAVE_STUDENTS = 'CLASSES::LOADING_SAVE_STUDENTS';
export const RECEIVE_SAVE_STUDENTS = 'CLASSES::RECEIVE_SAVE_STUDENTS';
export const ERROR_SAVE_STUDENTS = 'CLASSES::ERROR_SAVE_STUDENTS';

export const LOADING_DELETE_STUDENTS = 'CLASSES::LOADING_DELETE_STUDENTS';
export const RECEIVE_DELETE_STUDENTS = 'CLASSES::RECEIVE_DELETE_STUDENTS';
export const ERROR_DELETE_STUDENTS = 'CLASSES::ERROR_DELETE_STUDENTS';

export const LOADING_EDIT_ASSISTANCE = 'CLASSES::LOADING_EDIT_ASSISTANCE';
export const RECEIVE_EDIT_ASSISTANCE = 'CLASSES::RECEIVE_EDIT_ASSISTANCE';
export const ERROR_EDIT_ASSISTANCE = 'CLASSES::ERROR_EDIT_ASSISTANCE';

export const LOADING_REPLACEMENT_POLICIES = 'CLASSES::LOADING_REPLACEMENT_POLICIES';
export const RECEIVE_REPLACEMENT_POLICIES = 'CLASSES::RECEIVE_REPLACEMENT_POLICIES';
export const ERROR_REPLACEMENT_POLICIES = 'CLASSES::ERROR_REPLACEMENT_POLICIES';

export const LOADING_UPDATE_POLICY = 'CLASSES::LOADING_UPDATE_POLICY';
export const RECEIVE_UPDATE_POLICY = 'CLASSES::RECEIVE_UPDATE_POLICY';
export const ERROR_UPDATE_POLICY = 'CLASSES::ERROR_UPDATE_POLICY';

export const LOADING_GET_GROUP_CALENDAR = 'CLASSES::LOADING_GET_GROUP_CALENDAR';
export const RECEIVE_GET_GROUP_CALENDAR = 'CLASSES::RECEIVE_GET_GROUP_CALENDAR';
export const ERROR_GET_GROUP_CALENDAR = 'CLASSES::ERROR_GET_GROUP_CALENDAR';

export const LOADING_GET_STUDY_PLAN = 'CLASSES::LOADING_GET_STUDY_PLAN';
export const RECEIVE_GET_STUDY_PLAN = 'CLASSES::RECEIVE_GET_STUDY_PLAN';
export const ERROR_GET_STUDY_PLAN = 'CLASSES::ERROR_GET_STUDY_PLAN';

export const UPDATE_STUDENTS = 'CLASSES::UPDATE_STUDENTS';


export const ACTIVE_KEY = 'CLASSES::ACTIVE_KEY';

export const DOWNLOAD_CLIENT_REPORT = 'CLASSES::DOWNLOAD_CLIENT_REPORT';