import { Card, Col, Form, Radio, Row, Typography } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';
import FormWrapper from "../FormWrapper";
import { editAssistance } from "containers/Classes/actions";
import TypesController from "controllers/TypesController";
import { useEffect, useState } from "react";
import "./EditAssistanceClassModal.scss";

function EditAssistanceClassModal(props) {
  const [formValues, setFormValues] = useState({});
  const { onClose, students, classDetail, editAssistance, classReducer } = props;
  const { scheduleDate } = classDetail;
  const attendanceTypes = [
    {
      label: 'P',
      value: 'P',
      isPresent: true
    },
    {
      label: 'R10',
      value: 'R10',
      isPresent: true
    },
    {
      label: 'R15',
      value: 'R15',
      isPresent: true
    },
    {
      label: 'R20',
      value: 'R20',
      isPresent: true
    },
    {
      label: 'R30',
      value: 'R30',
      isPresent: true
    },
    {
      label: 'R30+',
      value: 'R30+',
      isPresent: true
    },
    {
      label: 'S',
      value: 'S'
    },
    {
      label: 'M-',
      value: 'M-'
    },
    {
      label: 'M+',
      value: 'M+'
    },
    {
      label: 'C+',
      value: 'C+'
    },
    {
      label: 'C-',
      value: 'C-'
    }
  ];

  const [form] = Form.useForm();

  const onFinish = () => {
    const payload = [];

    Object.values(form.getFieldsValue()).forEach((studentAssitance) => {
      studentAssitance.isPresent = attendanceTypes.find((type) => type.value === studentAssitance.delayType).isPresent;
      studentAssitance.attendanceAtFinishToClass = false;
      payload.push(studentAssitance)
    });

    editAssistance(classDetail.id, payload);
  };

  const handleOnFormChange = () => {
    setFormValues(form.getFieldsValue());
  };

  useEffect(() => {
    if (classReducer.loading.EDIT_ASSISTANCE?.state === 'success') {
      onClose();
    }
  }, [classReducer.loading.EDIT_ASSISTANCE]);

  return (
    <div className="class-modal">
      <h1>Asistencias del {moment(scheduleDate, 'DD-MM-YYYY').format('DD [de] MMMM')}</h1>
      <Card title={classDetail.clientName} type="inner">
        <Col>
          <Row>
            <Typography.Text><span>Grupo:</span> {classDetail.classGroup}</Typography.Text>
          </Row>
          <Row>
            <Typography.Text><span>Horario: </span>{classDetail.scheduleTime}</Typography.Text>
          </Row>
        </Col>
      </Card>
      <div>
        <FormWrapper
          name="class-assitance"
          form={form}
          onValuesChange={handleOnFormChange}
          onFinish={onFinish}
          loading={classReducer.loading.EDIT_ASSISTANCE?.state === true}
          cancelButtonText="Regresar"
          saveButtonText="Continuar"
          onRedirectCancelButton={() => onClose()}
        >
          <div className="container-form">
            <Typography.Text><span>Alumnos:</span></Typography.Text>
            {(students || []).map((student, key) => (
              <Card type="inner" key={key}>
                <Form.Item
                  hidden
                  name={[key, 'name']}
                  initialValue={student.name}
                >
                  <input />
                </Form.Item>
                <Form.Item
                  hidden
                  name={[key, 'nid']}
                  initialValue={student.nid}
                >
                  <input />
                </Form.Item>
                <Form.Item
                  label={student.name}
                  name={[key, "delayType"]}
                  initialValue={student.attendance}
                >
                  <Radio.Group
                    options={attendanceTypes}
                  />
                </Form.Item>
                <TypesController cancellationType={formValues[key]?.delayType || student.attendance}>
                  {({ reasonsForAbsence }) => (
                    ((!!reasonsForAbsence.length) && (
                      <Form.Item
                        label="Motivo de la falta"
                        name={[key, "reasonIDForAbsence"]}
                        initialValue={student.reasonIdForAbsence}
                      >
                        <Radio.Group
                          options={(reasonsForAbsence)
                            .map((item) => ({
                              value: item.id,
                              label: item.description
                            }))}
                        />
                      </Form.Item>
                    ))
                  )}
                </TypesController>
              </Card>
            ))}
          </div>
        </FormWrapper>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editAssistance }, dispatch);
const mapStateToProps = (state) => ({
  classReducer: state.get("classReducer"),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAssistanceClassModal);
