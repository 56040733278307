import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TableContainer from "../../../components/custom/TableContainer";
import ImagePreview from "../../../components/simple/ImagePreview";
import { FileFilled } from '@ant-design/icons';
import * as Actions from '../../../reducers/group-event/actions';

export const ClassEvent = (props) => {
  const columns = {
    events: [
      {
        title: "Fecha Evento",
        dataIndex: "date",
        key: "date",
        render: (value) => moment(value).format("DD/MM/YYYY"),
      },
      {
        title: "Evento",
        dataIndex: "event_name",
        key: "event_name",
      },
      {
        title: "Motivo",
        key: "reason_name",
        dataIndex: "reason_name",
      },
      {
        title: "Evidencias",
        dataIndex: "evidences",
        key: "evidences",
        render: (data) => (
          data && <ImagePreview data={data} isIcon={true} component={FileFilled} />
        ),
      },
    ],
  };

  let { match, state } = props;
  let { loading } = state;

  return(
    <TableContainer
      downloadDisabled={ true }
      entity={ match.params }
      loading={ loading?.GROUP_EVENT_LOADING?.state }
      name='events'
      columns={ columns }
      wantSaveFilters={ true }
      { ...props }
    />
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...Actions }, dispatch);

const mapStateToProps = (state) => ({
  state: state.get('groupEventReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassEvent);
