import * as ACTION from "./types";

import { getLocalDate, determineDayOfWeek, daysOfWeek } from '../../utils/dateFormats';

const initialState = {
  loading: {},
  currentDate: getLocalDate(),
  classes: {},
  classesOrigin: [],
  classDetail: {
    group: {
      replacementPolicy: { }
    },
    client: { },
    teacher: { }
  },
  replacementPolicies: [],
  catalogs: {},
  cancellatios:{},
  
  viewSelected: 'week',
  filters: {
    clients: [],
    teachers: [],
    languages: [],
    levels: [],
    groups: [],
    tags: {},
    isTrial: false,
    isPresentation: false,
    isObservation: false,
  },
  activeKey: '0'
};

function reformatClasses(data) {
  const hours = {};
  if(data.result){
    data.result.filter(item => item.date !== '').map((day) => {
      if(!hours[day.time_start]) { hours[day.time_start] = []; }
      hours[day.time_start].push(day);
      return day;
    });
    Object.values(hours).forEach(hour => {
      let week = { Sun: [], Mon: [], Tue: [], Wed: [], Thu: [], Fri: [], Sat: []}
      let time = '';
      hour.map((day) => {
        const parts = day.date.split('-');
        const date = `${parts[2]}-${parts[1]}-${parts[0]}`;
        const key = determineDayOfWeek(new Date(date));
        week[key] = day.classes;
        if(time === '') {
          time = day.time_start;
        }
        return day;
      });
      hours[time] = { hour: time, week };
    });
  }
  const newData = data;
  newData.result = hours;
  return newData;
}

function reformatCatalogs(data) {
  const newData = data;
  if(data && data.result){
    const names = Object.getOwnPropertyNames(data.result);
    const theData = [];
    names.forEach(item => {
      data.result[item].map((reg, index) => {
        if(data.result[item][index].id !== ''){
          data.result[item][index].title = reg.name;
        }
        return reg;
      })
      theData.push({
        title: item,
        children: data.result[item]
      });
    })
    newData.result = theData;
  }
  return newData;
}

const reducer = (state = initialState, action) => {
  switch(action.type){

    case ACTION.ADD_DATA:
      return { ...state, ...action.data };

    case ACTION.ACTIVE_KEY:
      return { ...state, activeKey: action.key };

    case ACTION.LOADING_GET_CLASSES:
      return { ...state, loading: { ...state.loading, GET_CLASSES: { state: true } } }
    case ACTION.RECEIVE_GET_CLASSES:
      return { ...state, loading: { ...state.loading, GET_CLASSES: undefined }, classesOrigin: action.result?.data?.result, classes: reformatClasses(action.result.data) }
    case ACTION.ERROR_GET_CLASSES:
      return { ...state, loading: { ...state.loading, GET_CLASSES: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_STUDENTS:
      return { ...state, loading: { ...state.loading, GET_STUDENTS: { state: true } }, groupStudents: [] }
    case ACTION.RECEIVE_GET_STUDENTS:
      return { ...state, loading: { ...state.loading, GET_STUDENTS: undefined }, groupStudents: action.result.data }
    case ACTION.ERROR_GET_STUDENTS:
      return { ...state, loading: { ...state.loading, GET_STUDENTS: { state: false, detail: action.error } } }

    case ACTION.LOADING_SAVE_STUDENTS:
      return { ...state, loading: { ...state.loading, SAVE_STUDENTS: { state: true } } }
    case ACTION.RECEIVE_SAVE_STUDENTS:
      return { ...state, loading: { ...state.loading, SAVE_STUDENTS: undefined }, saveStudents: action.result.data }
    case ACTION.ERROR_SAVE_STUDENTS:
      return { ...state, loading: { ...state.loading, SAVE_STUDENTS: { state: false, detail: action.error } } }

    case ACTION.LOADING_DELETE_STUDENTS:
      return { ...state, loading: { ...state.loading, DELETE_STUDENTS: { state: true } } }
    case ACTION.RECEIVE_DELETE_STUDENTS:
      return { ...state, loading: { ...state.loading, DELETE_STUDENTS: undefined }, deleteStudents: action.result.data }
    case ACTION.ERROR_DELETE_STUDENTS:
      return { ...state, loading: { ...state.loading, DELETE_STUDENTS: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_GROUP_CALENDAR:
      return { ...state, loading: { ...state.loading, GET_GROUP_CALENDAR: { state: true } }, groupSchedule: null }
    case ACTION.RECEIVE_GET_GROUP_CALENDAR:
      return { ...state, loading: { ...state.loading, GET_GROUP_CALENDAR: undefined }, groupSchedule: action.result.data }
    case ACTION.ERROR_GET_GROUP_CALENDAR:
      return { ...state, loading: { ...state.loading, GET_GROUP_CALENDAR: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_CLASS:
      return { ...state, loading: { ...state.loading, GET_CLASS: { state: true } } }
    case ACTION.RECEIVE_GET_CLASS:
      return { ...state, loading: { ...state.loading, GET_CLASS: undefined }, classDetail: { ...action.result.data.result, group: { ...action.result.data.result.group, scheduleDays: daysOfWeek(action.result.data.result.group.scheduleDays) } }}
    case ACTION.ERROR_GET_CLASS:
      return { ...state, loading: { ...state.loading, GET_CLASS: { state: false, detail: action.error } } }

    case ACTION.LOADING_REPLACEMENT_POLICIES:
      return { ...state, loading: { ...state.loading, REPLACEMENT_POLICIES: { state: true } } }
    case ACTION.RECEIVE_REPLACEMENT_POLICIES:
      return { ...state, loading: { ...state.loading, REPLACEMENT_POLICIES: undefined }, replacementPolicies: action.result.data.result }
    case ACTION.ERROR_REPLACEMENT_POLICIES:
      return { ...state, loading: { ...state.loading, REPLACEMENT_POLICIES: { state: false, detail: action.error } } }

    case ACTION.LOADING_UPDATE_POLICY:
      return { ...state, loading: { ...state.loading, UPDATE_POLICY: { state: true } } }
    case ACTION.RECEIVE_UPDATE_POLICY:
      return { ...state, loading: { ...state.loading, UPDATE_POLICY: undefined }}
    case ACTION.ERROR_UPDATE_POLICY:
      return { ...state, loading: { ...state.loading, UPDATE_POLICY: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_CATALOGS:
      return { ...state, loading: { ...state.loading, GET_CATALOGS: { state: true } } }
    case ACTION.RECEIVE_GET_CATALOGS:
      return { ...state, loading: { ...state.loading, GET_CATALOGS: undefined }, catalogs: reformatCatalogs(action.result.data) }
    case ACTION.ERROR_GET_CATALOGS:
      return { ...state, loading: { ...state.loading, GET_CATALOGS: { state: false, detail: action.error } } }  

    case ACTION.LOADING_GET_CLIENT_GROUPS:
      return { ...state, loading: { ...state.loading, GET_CLIENT_GROUPS: { state: true } }, clientGroups: [ ] }
    case ACTION.RECEIVE_GET_CLIENT_GROUPS:
      return { ...state, loading: { ...state.loading, GET_CLIENT_GROUPS: undefined }, clientGroups: action.result.data.result }
    case ACTION.ERROR_GET_CLIENT_GROUPS:
      return { ...state, loading: { ...state.loading, GET_CLIENT_GROUPS: { state: false, detail: action.error } } }

    case ACTION.CLEAR_CLIENT_GROUPS:
      return { ...state, clientGroups: [ ] }

    case ACTION.LOADING_GET_STUDY_PLAN:
      return { ...state, loading: { ...state.loading, GET_STUDY_PLAN: { state: true } } }
    case ACTION.RECEIVE_GET_STUDY_PLAN:
      return { ...state, loading: { ...state.loading, GET_STUDY_PLAN: undefined }, groupStudyPlan: action.result.data }
    case ACTION.ERROR_GET_STUDY_PLAN:
      return { ...state, loading: { ...state.loading, GET_STUDY_PLAN: { state: false, detail: action.error } } }

    case ACTION.LOADING_EDIT_ASSISTANCE:
      return { ...state, loading: { ...state.loading, EDIT_ASSISTANCE: { state: true } } }
    case ACTION.RECEIVE_EDIT_ASSISTANCE:
      return { ...state, loading: { ...state.loading, EDIT_ASSISTANCE: { state: 'success' } } }
    case ACTION.ERROR_EDIT_ASSISTANCE:
      return { ...state, loading: { ...state.loading, EDIT_ASSISTANCE: { state: false, detail: action.error } } }

    default:
      return state;
  }
}

export default reducer;
