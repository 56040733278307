export const LOADING_GET_CANCELLATIONS = 'CANCELLATIONS::LOADING_GET_CANCELLATIONS';
export const RECEIVE_GET_CANCELLATIONS = 'CANCELLATIONS::RECEIVE_GET_CANCELLATIONS';
export const ERROR_GET_CANCELLATIONS = 'CANCELLATIONS::ERROR_GET_CANCELLATIONS';

export const LOADING_GET_REPOSITIONS = 'CANCELLATIONS::LOADING_GET_REPOSITIONS';
export const RECEIVE_GET_REPOSITIONS = 'CANCELLATIONS::RECEIVE_GET_REPOSITIONS';
export const ERROR_GET_REPOSITIONS = 'CANCELLATIONS::ERROR_GET_REPOSITIONS';

export const LOADING_CREATE_REPOSITIONS = 'CANCELLATIONS::LOADING_CREATE_REPOSITIONS';
export const RECEIVE_CREATE_REPOSITIONS = 'CANCELLATIONS::RECEIVE_CREATE_REPOSITIONS';
export const ERROR_CREATE_REPOSITIONS = 'CANCELLATIONS::ERROR_CREATE_REPOSITIONS';

export const LOADING_GET_CANCELLATION = 'CANCELLATION::LOADING_GET_CANCELLATION';
export const RECEIVE_GET_CANCELLATION = 'CANCELLATION::RECEIVE_GET_CANCELLATION';
export const ERROR_GET_CANCELLATION = 'CANCELLATIONS::ERROR_GET_CANCELLATION';

export const TABLEPROPS_GET_CANCELLATIONS = 'CANCELLATIONS::TABLEPROPS_GET_CANCELLATIONS'
export const SAVE_FILTERS = 'CANCELLATIONS::SAVE_FILTERS'

export const RESET_STATE = 'CANCELLATIONS::RESET_STATE';