import * as ACTION from './types';
import { classesService } from '../../services/classes.service'

export function getCancellations(range, filters, page, orderBy, groupId) {
  return {
    types: [
      ACTION.LOADING_GET_CANCELLATIONS,
      ACTION.RECEIVE_GET_CANCELLATIONS,
      ACTION.ERROR_GET_CANCELLATIONS,
    ], 
    promise: classesService.getGroupCancellations(groupId, range, filters, page, orderBy)
  }
}

export function getCancellation(groupId, date) {
  return {
    types: [
      ACTION.LOADING_GET_CANCELLATION,
      ACTION.RECEIVE_GET_CANCELLATION,
      ACTION.ERROR_GET_CANCELLATION,
    ], 
    promise: classesService.getGroupCancellations(groupId, {
      startdate: date.set({ hour: 0, minute: 0, second: 0,millisecond: 0 }).valueOf(),
      enddate: date.set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).valueOf()
    }, {}, 1, 'date')
  }
}

export function tableProps(name, page, sort) {
  return {
    type: ACTION.TABLEPROPS_GET_CANCELLATIONS,
    name,
    page,
    sort
  }
}

export function saveFilters(filters) {
  return { type: ACTION.SAVE_FILTERS, filters }
}

export function resetStateCancellations() {
  return { type: ACTION.RESET_STATE }
}

export function getRepositions() {
  return {
    types: [
      ACTION.LOADING_GET_REPOSITIONS,
      ACTION.RECEIVE_GET_REPOSITIONS,
      ACTION.ERROR_GET_REPOSITIONS,
    ], 
    promise: classesService.getRepositions()
  }
}

export function createRepositions(id, data) {
  return {
    types: [
      ACTION.LOADING_CREATE_REPOSITIONS,
      ACTION.RECEIVE_CREATE_REPOSITIONS,
      ACTION.ERROR_CREATE_REPOSITIONS,
    ], 
    promise: classesService.createReposition(id, data)
  }
}