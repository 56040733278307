import client from '../axios';
import moment from 'moment';

class ClasesService {
  constructor() {
    this.client = client;
  }

  getClasses(payload) {
    return client({
      method: 'post',
			url: 'v1/auth/classes',
			params: {
        startdate: payload.startdate,
        enddate: payload.enddate,
      },
      data: {
        clients: payload.clients,
        groups: payload.groups,
        teachers: payload.teachers,
        languages: payload.languages,
        levels: payload.levels
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  getCatalogs(payload) {
		return client({
      method: 'post',
      url: `v1/auth/calendar/filters`,
      params: {
        startdate: payload.startdate,
        enddate: payload.enddate,
      },
      data: {
        clients: payload.clients,
        teachers: payload.teachers,
        languages: payload.languages,
        levels: payload.levels
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  getClientGroups(clientId, startdate, enddate) {
		return client({
      method: 'get',
      url: `v1/auth/clients/${ clientId }/filter-groups`,
      params: {
        startdate,
        enddate
      }
    });
  }


  getLanguages() {
		return client.get(`v1/auth/catalogues/languages`);
  }

  getTeachers(data, page, order_by) {
		return client.post(`v1/auth/teachers`, {
      page,
      limit: 10,
      order_by,
      ...data
    });
  }
  
  getAllTeachers() {
		return client.get(`v1/auth/teachers`);
  }

  getStudents() {
    return client.get(`v1/auth/students`);
  }

  getClass( idClass ) {
    return client.get(
      `v1/auth/classes/${idClass}`
    );
  }
  
  getReplacementPolicies() {
    return client.get(
      `v1/auth/replacementPolicies`
    ); 
  }

  updateReplacementPolicy(id, replacementPolicy) {
    return client.patch(
      `v1/auth/groups/${ id }/replacementPolicies`,
      {
        id,
        replacementPolicy: {
          id: replacementPolicy
        }
      }
    ); 
  }

  setCancellation(idClass, cancellationTypeID, reasonIDForCancellation, students) {
    const attendance = (students || []).map(student => ({
      ...student,
      delayType: cancellationTypeID,
      reasonIDForAbsence: reasonIDForCancellation,
      isPresent: false,
      attendanceAtFinishToClass: false
    }));

    return client.patch(
      `v1/auth/classes/${idClass}`, {
        cancellationTypeID,
        reasonIDForCancellation,
        isCancelled: true,
        endTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        attendance
      }
    );
  }

  getGroupStudents(clientId, classNID) {
		return client.get(`/v1/auth/clients/${ clientId }/classes/${ classNID }/students`);
  }

  getGroupCalendar(clientId, groupId, range) {
		return client.get(`/v1/auth/clients/${ clientId }/groups/${ groupId }/calendar`, {
      params: {
        startdate: range.start.format('YYYY-MM-DD'),
        enddate: range.end.format('YYYY-MM-DD')
      }
    });
  }

  getStudyPlan(clientId, groupId) {
    return client.get(`v1/auth/clients/${ clientId }/groups/${ groupId }/study-plan`)
  }

  saveStudents(clientId, groupId, props) {
		return client.post(
      `v1/auth/clients/${ clientId }/groups/${ groupId }/students`,
      props
    );
  }

  deleteStudents(clientId, groupId, props) {
		return client.delete(
      `v1/auth/clients/${ clientId }/groups/${ groupId }/students`,
      {
        data: props
      }
    );
  }

	getGroupScores({ groupId }) {
		return client.get(
      `v1/auth/group/${groupId}/scores`,
      {}
    );
  }

	getTeacher(id) {
		return client.get(`v1/auth/teachers/${ id }`);
  }
  
  getTypes() {
    return client.get(`v1/types`);
  }

  getAgenda(teachers, range) {
    return client({
      method: 'post',
			url: `v1/auth/agendas/teachers`,
			params: {
        startdate: range.init,
        enddate: range.end,
      },
      data: {
        teachers
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }


  saveLock(id, data) {
    return client.post(`/v1/auth/teachers/${ id }/agenda/events`, data);
  }

  removeLock(id, event, all) {
    let type = all ? 'all' : 'only';
    return client.delete(`/v1/auth/teachers/${ id }/agenda/events/${ event.eventGroupID }`, {
      data: {
        type,
        startTime: event.start.valueOf()
      }
    });
  }


  getGroupExams(groupId, range={}, filters, page, orderBy) {
    return client.post(`/v1/auth/groups/${ groupId }/exams`, {
        filters: {
            startdate: range.startdate,
            enddate: range.enddate,
            ...filters
        },
        pagination: {
            page,
            limit: 10,
            orderBy: orderBy.column,
            sort: orderBy.order
        }
    })
  }

  getGroupCancellations(groupId, range={}, filters, page, orderBy) {
    return client.post(`/v1/auth/groups/${ groupId }/cancellations`, {
        filters: {
            startdate: range.startdate,
            enddate: range.enddate,
            ...filters
        },
        pagination: {
            page,
            limit: 10,
            orderBy: orderBy.column,
            sort: orderBy.order
        }
    })
  }

  getRepositions() {
    return client.get(`/v1/auth/teacher/replacements?status=pending`);
  }

  createReposition(id, data) {
    return client.post(`v1/auth/classes/${ id }/replacements` , data);
  }
  
  getExamDetail(group, id) {
    return client({
      method: 'get',
			url: `v1/auth/groups/${ group }/exams/${ id }`
    });
  }

  clientReport(clientId, range, addDelays) {
    window.open(`${ client.defaults.baseURL }v1/auth/clients/${ clientId }/report?startdate=${ range.startdate }&enddate=${ range.enddate  }&viewDelaysAtReport=${ addDelays }`, "_blank");
  }

  editAssistance(id, data) {
    return client.patch(`v1/auth/classes/${ id }`, {
      "attendance": data
  });
  }
}

export const classesService = new ClasesService();
