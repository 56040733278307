export const GROUP_EXAMS_LOADING = 'GROUP_EXAMS::GROUP_EXAMS_LOADING'
export const GROUP_EXAMS_RECEIVE = 'GROUP_EXAMS::GROUP_EXAMS_RECEIVE'
export const GROUP_EXAMS_ERROR = 'GROUP_EXAMS::GROUP_EXAMS_ERROR'

export const STUDENTS_LOADING = 'GROUP_EXAMS::STUDENTS_LOADING'
export const STUDENTS_RECEIVE = 'GROUP_EXAMS::STUDENTS_RECEIVE'
export const STUDENTS_ERROR = 'GROUP_EXAMS::STUDENTS_ERROR'

export const GROUP_EXAMS_TABLEPROPS = 'GROUP_EXAMS::GROUP_TABLEPROPS'
export const SAVE_FILTERS = 'GROUP_EXAMS::SAVE_FILTERS'

export const RESET_STATE = 'GROUP_EXAMS::RESET_STATE'