import { tableSortProps, selectedRowKeys } from '../helpers/table';
import * as ACTION from "./types";

const initialState = {
  loading: {
    EDIT_SCHEDULE: {}
  },
  errors: { },
  sort: { },
  page: {
    completedGroups: 1,
    incompleteGroups: 1
  },
  orderBy: {
    completedGroups: {
      column: "created",
      order: "ASC"
    },
    incompleteGroups: {
      column: "created",
      order: "ASC"
    },
    column: "created",
    order: "ASC"
  }
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    
    case ACTION.TABLE_PROPS:
      return { ...tableSortProps(state, action) };

    case ACTION.LOADING_GET_COMPLETED_GROUPS:
      return { ...state, loading: { ...state.loading, GET_COMPLETED_GROUPS: { state: true } } }
    case ACTION.RECEIVE_GET_COMPLETED_GROUPS:
      return { ...state, loading: { ...state.loading, GET_COMPLETED_GROUPS: undefined }, completedGroups: action.result.data.result }
    case ACTION.ERROR_GET_COMPLETED_GROUPS:
      return { ...state, loading: { ...state.loading, GET_COMPLETED_GROUPS: { state: false, detail: action.error } } }
  
    case ACTION.LOADING_GET_INCOMPLETE_GROUPS:
      return { ...state, loading: { ...state.loading, GET_INCOMPLETE_GROUPS: { state: true } } }
    case ACTION.RECEIVE_GET_INCOMPLETE_GROUPS:
      return {
        ...state,
        loading: { ...state.loading, GET_INCOMPLETE_GROUPS: undefined },
        incompleteGroups: action.result.data.result,
        selectedIncompleteGroupRowKeys: selectedRowKeys(action.result.data.result.result, state.selection)
      }
    case ACTION.ERROR_GET_INCOMPLETE_GROUPS:
      return { ...state, loading: { ...state.loading, GET_INCOMPLETE_GROUPS: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_GROUP:
      return { ...state, loading: { ...state.loading, GET_GROUP: { state: true } } }
    case ACTION.RECEIVE_GET_GROUP:
      return { ...state, loading: { ...state.loading, GET_GROUP: undefined }, group: action.result.data, savedGroup: null }
    case ACTION.ERROR_GET_GROUP:
      return { ...state, loading: { ...state.loading, GET_GROUP: { state: false, detail: action.error } } }
    
    case ACTION.LOADING_SAVE_GROUP:
      return { ...state, loading: { ...state.loading, SAVE_GROUP: { state: true } }, savedGroup: null }
    case ACTION.RECEIVE_SAVE_GROUP:
      return { ...state, loading: { ...state.loading, SAVE_GROUP: undefined }, savedGroup: action.result.data.result }
    case ACTION.ERROR_SAVE_GROUP:
      return { ...state, loading: { ...state.loading, SAVE_GROUP: { state: false, detail: action.error } }, savedGroup: null }

    case ACTION.LOADING_EDIT_GROUP_COMPLETED:
      return { ...state, loading: { ...state.loading, EDIT_GROUP_COMPLETED: { state: true } }, savedGroup: null }
    case ACTION.RECEIVE_EDIT_GROUP_COMPLETED:
      return { ...state, loading: { ...state.loading, EDIT_GROUP_COMPLETED: undefined }, savedGroup: action.result.data.result }
    case ACTION.ERROR_EDIT_GROUP_COMPLETED:
      return { ...state, loading: { ...state.loading, EDIT_GROUP_COMPLETED: { state: false, detail: action.error } }, savedGroup: null }

    case ACTION.LOADING_SAVE_SCHEDULE:
      return { ...state, loading: { ...state.loading, SAVE_SCHEDULE: { state: true } } }
    case ACTION.RECEIVE_SAVE_SCHEDULE:
      return { ...state, loading: { ...state.loading, SAVE_SCHEDULE: undefined } }
    case ACTION.ERROR_SAVE_SCHEDULE:
      return { ...state, loading: { ...state.loading, SAVE_SCHEDULE: { state: false, detail: action.error } } }

    case ACTION.LOADING_EDIT_SCHEDULE:
      return { ...state, loading: { ...state.loading, EDIT_SCHEDULE: { state: true } } }
    case ACTION.RECEIVE_EDIT_SCHEDULE:
      return { ...state, loading: { ...state.loading, EDIT_SCHEDULE: undefined } }
    case ACTION.ERROR_EDIT_SCHEDULE:
      return { ...state, loading: { ...state.loading, EDIT_SCHEDULE: { state: false, detail: action.error } } }

    case ACTION.LOADING_ASSIGN_TEACHER:
      return { ...state, loading: { ...state.loading, ASSIGN_TEACHER: { state: true } } }
    case ACTION.RECEIVE_ASSIGN_TEACHER:
      return { ...state, loading: { ...state.loading, ASSIGN_TEACHER: undefined } }
    case ACTION.ERROR_ASSIGN_TEACHER:
      return { ...state, loading: { ...state.loading, ASSIGN_TEACHER: { state: false, detail: action.error } } }
  
    case ACTION.LOADING_ASSIGN_MSACLASS_TEACHER:
      return { ...state, loading: { ...state.loading, ASSIGN_MSACLASS_TEACHER: { state: true } } }
    case ACTION.RECEIVE_ASSIGN_MSACLASS_TEACHER:
      return { ...state, loading: { ...state.loading, ASSIGN_MSACLASS_TEACHER: { state: 'success' } } }
    case ACTION.ERROR_ASSIGN_MSACLASS_TEACHER:
      return { ...state, loading: { ...state.loading, ASSIGN_MSACLASS_TEACHER: { state: false, detail: action.error } } }
  
    case ACTION.LOADING_ACTIVATE_GROUPS:
      return { ...state, loading: { ...state.loading, ACTIVATE_GROUPS: { state: true } } }
    case ACTION.RECEIVE_ACTIVATE_GROUPS:
      return { ...state,
        loading: { ...state.loading, ACTIVATE_GROUPS: undefined },
        selectedIncompleteGroups: [ ],
        selectedIncompleteGroupRowKeys: [ ]
      }
    case ACTION.ERROR_ACTIVATE_GROUPS:
      return { ...state, loading: { ...state.loading, ACTIVATE_GROUPS: { state: false, detail: action.error } } }
  
    case ACTION.INCOMPLETE_GROUP_ROW_SELECTION:
      let selectedIncompleteGroups = (state.selectedIncompleteGroups || [ ])
                            .filter(a => (state.incompleteGroups.result).find(b => a.id === b.id) ? false : true)
                            .concat(action.items);
      return { ...state,
          selectedIncompleteGroups,
          selectedIncompleteGroupRowKeys: selectedRowKeys(state.incompleteGroups.result, selectedIncompleteGroups)
      };
    
    case ACTION.ONE_INCOMPLETE_GROUP_ROW_SELECTION:
      return { ...state,
          selectedIncompleteGroups: action.items,
          selectedIncompleteGroupRowKeys: selectedRowKeys(state.incompleteGroups.result, action.items)
      };
    

    case ACTION.LOADING_ASSIGN_MATERIALS:
      return { ...state, loading: { ...state.loading, ASSIGN_MATERIALS: { state: true } } }
    case ACTION.RECEIVE_ASSIGN_MATERIALS:
      return { ...state, loading: { ...state.loading, ASSIGN_MATERIALS: undefined } }
    case ACTION.ERROR_ASSIGN_MATERIALS:
      return { ...state, loading: { ...state.loading, ASSIGN_MATERIALS: { state: false, detail: action.error } } }
    
    case ACTION.LOADING_GET_TYPE_EVIDENCE:
      return { ...state, loading: { ...state.loading, GET_EVIDENCE: { state: true } } }
    case ACTION.RECEIVE_GET_TYPE_EVIDENCE:
      return { ...state, loading: { ...state.loading, GET_EVIDENCE: undefined }, typeEvidence: action.result.data }
    case ACTION.ERROR_GET_TYPE_EVIDENCE:
      return { ...state, loading: { ...state.loading, GET_EVIDENCE: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_TYPES:
      return { ...state, loading: { ...state.loading, GET_TYPES: { state: true } } }
    case ACTION.RECEIVE_GET_TYPES:
      return { ...state, loading: { ...state.loading, GET_TYPES: { state: 'success' } }, types: action.result.data.result }
    case ACTION.ERROR_GET_TYPES:
      return { ...state, loading: { ...state.loading, GET_TYPES: { state: false, detail: action.error } } }

    case ACTION.LOADING_SET_CANCELLATION:
      return { ...state, loading: { ...state.loading, SET_CANCELLATION: { state: true } } }
    case ACTION.RECEIVE_SET_CANCELLATION:
      return { ...state, loading: { ...state.loading, SET_CANCELLATION: { state: 'success' } } }
    case ACTION.ERROR_SET_CANCELLATION:
      return { ...state, loading: { ...state.loading, SET_CANCELLATION: { state: false, detail: action.error } } }

    case ACTION.RESET_LOADING_STATE:
      return { ...state, loading: initialState.loading }

    default:
      return state;
  }
}

export default reducer;
