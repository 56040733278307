import client from '../axios';

class GroupService {
  constructor() {
    this.client = client;
  }
  createGroup = data => client.put('v2/group', data);

  editGroupCompleted = (gid, data) => client.put(`v2/group/id/${gid}/completed`, data);

  getCompleted = (cid, page, orderBy, searchQuery) => client.get(`v2/client/id/${ cid }/groups-completed`, {
    params: {
      filters: {
        name: searchQuery
      },
      pagination: {
          page,
          limit: 10,
          orderBy: orderBy.column,
          sort: orderBy.order
      }
    }
  });

  getIncomplete = (cid, page, orderBy, searchQuery) => client.get(`v2/client/id/${ cid }/groups-incomplete`, {
    params: {
      filters: {
        name: searchQuery
      },
      pagination: {
          page,
          limit: 10,
          orderBy: orderBy.column,
          sort: orderBy.order
      }
    }
  });

  getGroup = gid => client.get(`v2/group/id/${ gid }/incomplete`);

  getGroupCompleted = gid => client.get(`v2/group/id/${ gid }/completed`);

  saveSchedule = (gid, data) => client.put(`v2/group/id/${ gid }/schedule`, data);

  saveScheduleInGroupCompleted = (gid, data) => client.put(`v2/group/id/${gid}/schedule-completed`, data);

  assignTeacher = (gid, data) => client.put(`/v2/group/id/${ gid }/teacher`, data)

  assignMSAClassTeacher = (gid, data) => client.patch(`/v2/group/id/${ gid }/msa-teacher`, data)

  activateGroups = (gids, listFile) => client.post(`/v2/group/activate`, {
    groups_id: gids,
    attachments: listFile
  })

  setTeacherTariff = (gid, tariff) => client.put(`/v2/group/id/${ gid }/tariff-teacher`, {
    tariff
  });

  assignMaterials = (gid, materials) => client.post(`/v2/material/group/${ gid }`, materials);

  getTypeEvidence = () => client.get(`/v2/group/type-evidence`);
}

export const groupService = new GroupService();
