import { setSortDirection } from "../helpers/table";
import * as ACTION from "./types";

const dataState = {
    loading: { },
    errors: { },
    cancellations: {
        result: [],
        catalogues: []
    },
    repositions: [],
    cancellation: {
        result: [],
        catalogues: []
    },
    page: {
        cancellations: 1
    },
    filters: {
        date: null,
        typeC: undefined,
        reasonC: undefined,
        dateR: null,
        typeR: undefined
    },
    orderBy: {
        cancellations: {
            column: "date",
            order: "DESC"
        }
    },
    form: {
        startTime: null,
        endTime: null,
        full_name: null,
        isApproved: null,
        status: null
    },
    types: [
        { value: 'Parcial', label: 'Parcial' },
        { value: 'Examen', label: 'Examen' },
        { value: 'Test de ubicación', label: 'Test de ubicación' },
    ],
    results: [
        { label: 'Sin realizar', value: 2 },
        { label: 'Aprobado', value: 1 },
        { label: 'No aprobado', value: 0 },
    ],
    status: [
        { label: 'Validado', value: 'Validado' },
        { label: 'Sin validar', value: 'Sin validar' },
    ]
};

const groupCancellation = (state = dataState, action) => {
    switch(action.type){
        case ACTION.SAVE_FILTERS:
            return {...state, filters: action.filters, page: 1 } ;
        case ACTION.LOADING_GET_CANCELLATIONS:
            return { ...state, loading: { ...state.loading, CANCELLATIONS: { state: true } } }
        case ACTION.RECEIVE_GET_CANCELLATIONS:
            return {...state, loading: { ...state.loading, CANCELLATIONS: undefined }, cancellations: action.result.data } ;
        case ACTION.ERROR_GET_CANCELLATIONS:
            return { ...state, loading: { ...state.loading, CANCELLATIONS: { state: false, detail: action.error } } }

        case ACTION.LOADING_GET_REPOSITIONS:
            return { ...state, loading: { ...state.loading, GET_REPOSITIONS: { state: true } } }
        case ACTION.RECEIVE_GET_REPOSITIONS:
            return {...state, loading: { ...state.loading, GET_REPOSITIONS: undefined }, repositions: action.result.data.result } ;
        case ACTION.ERROR_GET_REPOSITIONS:
            return { ...state, loading: { ...state.loading, GET_REPOSITIONS: { state: false, detail: action.error } } }

        case ACTION.LOADING_CREATE_REPOSITIONS:
            return { ...state, loading: { ...state.loading, CREATE_REPOSITIONS: { state: true } } }
        case ACTION.RECEIVE_CREATE_REPOSITIONS:
            return {...state, loading: { ...state.loading, CREATE_REPOSITIONS: { state: 'success' } }} ;
        case ACTION.ERROR_CREATE_REPOSITIONS:
            return { ...state, loading: { ...state.loading, CREATE_REPOSITIONS: { state: false, detail: action.error } } }

        case ACTION.LOADING_GET_CANCELLATION:
            return { ...state, loading: { ...state.loading, GET_CANCELLATION: { state: true } } }
        case ACTION.RECEIVE_GET_CANCELLATION:
            return {...state, loading: { ...state.loading, GET_CANCELLATION: undefined }, cancellation: action.result.data } ;
        case ACTION.ERROR_GET_CANCELLATION:
            return { ...state, loading: { ...state.loading, GET_CANCELLATION: { state: false, detail: action.error } } }

        case ACTION.TABLEPROPS_GET_CANCELLATIONS:
            state = setSortDirection(action, state);
            state.page[action.name] = action.page;
            return { ...state };

        case ACTION.RESET_STATE:
            return { ...dataState };

        default:
            return state;
    }
};

export default groupCancellation;