import axios from 'axios';
import { notification } from 'antd';

export const http = () => {
  let baseURL = 'https://api.s-peak.com/';

  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'Client-Timezone': `GMT${ new Date().toString().split('GMT')[1] }`
    },
    timeout: 120000
  });
};

const client = http();

client.interceptors.response.use(response => response, error => {

  const { data } = error.response;
  
  const description = data?.reason || data?.message;

  if (description) {
    notification['warning']({
      message: 'ERROR',
      description,
      placement: 'bottomRight'
    });
  }
  return Promise.reject(error);
});

export default client;
