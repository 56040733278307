import React, { useState } from "react";
import { Input, Form } from "antd";
import PropTypes from "prop-types";

const InputValidate = (props) => {

	const [value, setValue] = useState('')

  const {
    label = "",
    name,
    rules = [],
    placeholder = "",
    validateHelpText = "",
    stateValidation = false,
		invalidValue = false,
  } = props;

  let status, helpText;

  if (invalidValue && value) {
    status = "error";
    helpText = validateHelpText;
  } else if (stateValidation) {
    status = "validating";
  }

  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={status}
      help={helpText}
      hasFeedback
      rules={rules}
    >
      <Input
        data-testid="Input"
				autoComplete="off" 
				placeholder={placeholder} 
				onChange={setValue} />
    </Form.Item>
  );
};

InputValidate.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  rules: PropTypes.array,
  placeholder: PropTypes.string,
	validateHelpText: PropTypes.string,
	stateValidation: PropTypes.bool,
	invalidValue: PropTypes.bool,

};

export default InputValidate;
