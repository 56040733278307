import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Empty, Drawer, List, Typography } from "antd";
import Button from 'components/simple/Button'
import { getRepositions } from "reducers/group-cancellation/actions";
import moment from "moment";
import { useLocation } from "react-router-dom";
import "./RepositionSidebar.scss";
import RepositionModal from "../RepositionModal";
import ModalController from "controllers/ModalController";
import ModalClosable from "../ModalClosable";

const cancellationPolicy = {
  SPEAK_POLICY: 1,
  CLOSED_POLICY: 2,
  OPEN_POLICY: 3,
};

function RepositionSidebar(props) {
  const {
    groupCancellationReducer,
    getRepositions,
  } = props;

  const location = useLocation();

  useEffect(() => {
    getRepositions();
  }, [location.pathname]);

  const availableRepositions = (groupCancellationReducer.repositions || []).filter(item => item.replacementPolicy !== cancellationPolicy.CLOSED_POLICY);;
  const hasRepositions = availableRepositions.length > 0;

  return (
    <div className="RepositionSidebar">
      <ModalController
        trigger={({onToggleModal}) => (
          <Button
            type="primary"
            onClick={onToggleModal}
            loading={groupCancellationReducer.loading.GET_REPOSITIONS?.state === true}
          >Reposiciones ({availableRepositions.length})</Button>
        )}
        modal={({isModalOpen, onToggleModal}) => (
          <Drawer
            title="Reposiciones pendientes"
            placement={'right'}
            closable={true}
            onClose={onToggleModal}
            open={isModalOpen}
            key={'right'}
            className="RepositionSidebar__drawer"
          >
            {!hasRepositions && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {hasRepositions && (
              <List
                itemLayout="vertical"
                dataSource={availableRepositions}
                renderItem={reposition => {
                  const originalClassDate = moment(reposition.scheduleDate, 'DD-MM-YYYY').format('dddd DD [de] MMMM');
                  return (
                    <List.Item>
                      <List.Item.Meta
                        title={reposition.clientName}
                        description={reposition.classGroup}
                      />
                      <Typography.Text><span>Clase cancelada:</span> {originalClassDate}</Typography.Text>
                      <ModalController
                        trigger={({onToggleModal}) => (
                          <Button type="primary" onClick={onToggleModal}>Agendar reposición</Button>
                        )}
                        modal={({isModalOpen, onToggleModal}) => (
                          <ModalClosable
                            visible={ isModalOpen } 
                            placement="left"
                            children={
                              <RepositionModal reposition={reposition} onClose={onToggleModal}/>
                            }
                          />
                        )}
                      />
                    </List.Item>
                  )}
                }
              />
            )}
          </Drawer>
        )}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getRepositions }, dispatch);
const mapStateToProps = (state) => ({
  groupCancellationReducer: state.get("groupCancellationReducer"),
});

export default connect(mapStateToProps, mapDispatchToProps)(RepositionSidebar);
