import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import { Row, Col } from 'antd';
import TyphographyTitle from '../../components/simple/TypographyTitle';
import Card from '../../components/simple/Card';
import Tabs from '../../components/simple/Tabs';
import TicketForm from './TicketForm'
import Table from './Table'
import './Tickets.scss';

function Tickets(props) {
  let { tickets, setActiveKey, getTotals } = props,
      { loading, activeKey, totals } = tickets;
  let [ modalVisible, toggleModal ] = useState(false);
  let [ refresh, setRefresh ] = useState(0);
  let [ selectedTicket, setSelectedTicket ] = useState({
    priority: { },
    client: { },
    status: { },
    responsable: { }
  });
  let tableProps = {
    onRow: ticket => ({
      onClick: () => {
        toggleModal(true)
        setSelectedTicket(ticket);
      }
    })
  };
  useEffect(() => {
    getTotals();
    // eslint-disable-next-line
  }, [ refresh ]);
  return (
    <div className='Tickets'>
      <TyphographyTitle level={4}>Tickets</TyphographyTitle>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Row>
              <Col>
                <div className='indicator'>
                  <span>{ totals?.unattended }</span>
                  <label>Sin atender</label>
                </div>
              </Col>
              <Col>
                <div className='indicator'>
                  <span>{ totals?.inProcess }</span>
                  <label>En proceso</label>
                </div>
              </Col>
              <Col>
                <div className='indicator'>
                  <span>{ totals?.scaled }</span>
                  <label>Escalados</label>
                </div>
              </Col>
              <Col>
                <div className='indicator'>
                  <span>{ totals?.closed }</span>
                  <label>Cerrados</label>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Tabs
              defaultActiveKey={activeKey}
              items={[ 
                { value: 0, key:'0', label: 'Mis tickets', children: <Table { ...props } refreshTrigger={refresh} name='tickets' loading={ loading.TICKETS && loading.TICKETS.state } { ...tableProps } /> },
                { value: 1, key:'1', label: 'Todos', children: <Table { ...props } refreshTrigger={refresh} name='allTickets' loading={ loading.ALL_TICKETS && loading.ALL_TICKETS.state } { ...tableProps } /> },
              ]}
              onChange={setActiveKey}
            />
          </Card>
        </Col>
      </Row>
      <TicketForm visible={modalVisible} data={selectedTicket} onChange={() => {
        setRefresh(refresh + 1);
      }} onClose={() => toggleModal(false)}/>
    </div>
  );
}

export default connect((state) => ({
  tickets: state.get('tickets')
}), (dispatch) => {
  return bindActionCreators(Actions, dispatch);
})(Tickets);