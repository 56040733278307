import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTeachersByLanguage } from '../../../reducers/teachers/actions';
import { Select } from 'antd';
  
import './TeacherSelect.scss';

const { Option } = Select;

const TeacherSelect = ({studyPlanId, getTeachersByLanguage, teachersReducer, ...other}) => {

  const { teachersByLang } = teachersReducer;

  useEffect(_ => {
    if (studyPlanId) {
      getTeachersByLanguage(studyPlanId);
    }
  }, [ studyPlanId ]);  // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <Select
      showSearch
      placeholder='Seleccionar profesor'
      optionFilterProp="children"
      loading={ teachersReducer.loading.GET_BY_LANGUAGE?.state }
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
      }
      {...other}
    >
      { (teachersByLang || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.teacher_name }</Option>) }
    </Select>
  );
}


const mapDispatchToProps = dispatch => bindActionCreators({ getTeachersByLanguage }, dispatch);

const mapStateToProps = state => ({
  teachersReducer: state.get('teachersReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherSelect);